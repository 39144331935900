import React from "react";
import styled from "styled-components";
import { white, blueLight } from "../../styles/colors";

const Avatar = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${blueLight};
  text-align: center;
  border-radius: 50%;
  margin: auto;

  span {
    position: relative;
    top: ${props => props.size / 4}px;
    font-size: ${props => props.size / 3}px;
    line-height: ${props => props.size / 2}px;
    color: ${white};
    font-weight: 300;
  }
`;

export default function UserAvatar(props) {
  const { size, children } = props;
  return <Avatar size={size}>{children}</Avatar>;
}

UserAvatar.defaultProps = { size: 100 };
