import styled from "styled-components";

export const CheckboxItem = styled.div`
  // override the existing css that makes it impossible to use a standard html checkbox 😩

  &&& {
    input {
      opacity: 100;
      position: relative;
      left: 0;
      cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    }
    label {
      margin: 0 8px;
      cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    }
  }
`;
