import React from "react";
import styled from "styled-components";
import {
  gold,
  blue,
  red,
  grayLightest,
  grayLight
} from "../../../styles/colors";

const handleSize = size => {
  switch (size) {
    case "large":
      return "1.75rem 3rem";
    case "medium":
      return "1.25rem 2rem";
    case "small":
      return ".5rem .75rem";
    default:
      return ".5rem .75rem";
  }
};

const handleBackgroundColor = (color, disabled) => {
  if (disabled) return grayLightest;
  switch (color) {
    case "primary":
      return gold;
    case "secondary":
      return blue;
    case "danger":
      return red;
    default:
      return "#fff";
  }
};

const handleHoverColor = (color, disabled) => {
  if (disabled) return "";
  switch (color) {
    case "primary":
      return "#fecd6b";
    case "secondary":
      return "dodgerblue";
    case "danger":
      return "#d74c4c";
    default:
      return "#fff";
  }
};

const handleBorder = variant => {
  switch (variant) {
    case "outlined":
      return `1px solid ${blue}`;
    case "text":
      return "none";
    default:
      return "none";
  }
};

const handleFontSize = size => {
  switch (size) {
    case "large":
      return "18px";
    case "medium":
      return "16px";
    case "small":
      return "14px";
    default:
      return "14px";
  }
};

const handleColor = (color, disabled) => {
  if (disabled) return grayLightest;

  switch (color) {
    case "secondary":
      return blue;
    case "danger":
      return red;
    default:
      return blue;
  }
};

const StyledButton = styled.button`
  &&& {
    transition: all 0.4s ease-out;
    font-size: ${({ size }) => handleFontSize(size)};
    color: ${({ color, disabled }) =>
      disabled ? grayLight : color === "primary" ? "black" : "white"};
    border: none;
    border-radius: 4px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    padding: ${({ size }) => handleSize(size)};
    background: ${({ color, disabled }) =>
      handleBackgroundColor(color, disabled)};
    &:hover {
      background: ${({ color, disabled }) => handleHoverColor(color, disabled)};
    }
  }
`;

const OutlinedButton = styled(StyledButton)`
  &&& {
    border: 2px solid #2680fa;
    color: #2680fa;
    background: #e9f2fe;

    &:hover {
      background: #c4dcfd;
    }
  }
`;

const TextButton = styled(StyledButton)`
  background: none;
  color: ${({ color }) => handleColor(color)};

  &:hover {
    background: ${({ color }) => (color === "danger" ? `${red}` : "#e9f2fe")};
    color: ${({ color }) => (color === "danger" ? "white" : null)};
  }
`;

export function Button({
  size = "medium",
  color = "primary",
  variant,
  disabled = false,
  ...rest
}) {
  if (variant === "outlined") {
    return <OutlinedButton size={size} {...rest} />;
  }

  if (variant === "text") {
    return <TextButton size={size} color={color} {...rest} />;
  }

  return (
    <StyledButton
      size={size}
      color={color}
      variant={variant}
      {...rest}
      disabled={disabled}
    />
  );
}
