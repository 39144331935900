export const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_STARTED": {
      return {
        ...state,
        loading: true,
        success: false,
        response: null
      };
    }
    case "FETCH_COMPLETE": {
      return {
        ...state,
        loading: false,
        success: true,
        response: action.response
      };
    }
    case "FETCH_ERROR": {
      return {
        ...state,
        loading: false,
        success: false
      };
    }
    default: {
      return state;
    }
  }
};
