/* eslint-disable react/destructuring-assignment */
import React from "react";

export default function Select(props) {
  /*
   Props: {
     options*<array>     List of label and value pairs to populate the select field
     value<string>       Default value applied to the component
     name*<string>       Name to be applied to the field
     placeholder<string> Default first option in the list
     onSelect<func>      Callback function to be executed onChange of the select input
   }
  */

  const optionsForSelect = props.options.map(option => {
    let label;
    let value;
    if (typeof option === "object" && option.length > 1) {
      [label, value] = option;
    } else {
      label = option;
      value = option;
    }
    return (
      <option key={label} value={value}>
        {label}
      </option>
    );
  });
  const placeholder = props.placeholder || "Please select one";

  return (
    <select
      name={props.name}
      value={props.value}
      onChange={e => props.onSelect(e)}
    >
      <option key={placeholder} value="">
        {placeholder}
      </option>
      {optionsForSelect}
    </select>
  );
}
