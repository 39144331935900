import { render, fireEvent, within, waitFor } from "@testing-library/react";
import ApplicationDateQuestionaireWrapper from "./ApplicationDateQuestionareWrapper";

describe("The ApplicationDateQuestionare component", () => {
  beforeEach(() => {
    fetch.resetMocks();
  });

  it("renders without crashing", () => {
    const { container } = render(<ApplicationDateQuestionaireWrapper />);
    expect(container);
  });

  it("allows the user to input the necessary data", async () => {
    fetch.once(
      JSON.stringify({
        currentVisaBulletin: {},
        nextVisaBulletin: {},
        currentMonthStatus: true,
        nextMonthStatus: false,
        birthCountry: "China",
        spouseBirthCountry: "China",
        visaCategory: "EB-1",
        priorityDate: null
      })
    );

    const { getByTestId, getByText, queryAllByText } = render(
      <ApplicationDateQuestionaireWrapper
        countriesList={["China", "India", "Russia"]}
        visaCategoriesList={["EB-1", "EB-2", "EB-3"]}
      />
    );
    const birthCountrySelectDiv = getByTestId("select-birth-country");
    fireEvent.focus(birthCountrySelectDiv.querySelector("input"));
    fireEvent.keyDown(birthCountrySelectDiv.querySelector("input"), {
      key: "ArrowDown",
      code: 40
    });
    fireEvent.keyDown(birthCountrySelectDiv.querySelector("input"), {
      key: "Enter",
      code: 13
    });
    const visaCategorySelectDiv = getByTestId("select-visa-category");
    fireEvent.focus(visaCategorySelectDiv.querySelector("input"));
    fireEvent.keyDown(visaCategorySelectDiv.querySelector("input"), {
      key: "ArrowDown",
      code: 40
    });
    fireEvent.keyDown(visaCategorySelectDiv.querySelector("input"), {
      key: "Enter",
      code: 13
    });
    fireEvent.change(
      getByTestId("input-priority-date").querySelector("input"),
      {
        target: { value: "02/09/2020" }
      }
    );
    fireEvent.click(
      within(getByTestId("yes-no-married")).getByTestId("Yes-radio")
    );
    expect(
      getByText(
        "Will you and your spouse be filing the I-485 at the same time?"
      )
    );
    fireEvent.click(
      within(getByTestId("yes-no-filing-with-spouse")).getByTestId("Yes-radio")
    );
    expect(getByText("What is your spouse's country of birth?"));
    const spouseCountryOfBirthDiv = getByTestId(
      "select-spouse-country-of-birth"
    );
    fireEvent.focus(spouseCountryOfBirthDiv.querySelector("input"));
    fireEvent.keyDown(spouseCountryOfBirthDiv.querySelector("input"), {
      key: "ArrowDown",
      code: 40
    });
    fireEvent.keyDown(spouseCountryOfBirthDiv.querySelector("input"), {
      key: "Enter",
      code: 13
    });
    fireEvent.click(getByText("Check your eligibility"));
    await waitFor(() =>
      getByText(
        "What now? We can walk you through your options and answer any questions free of charge."
      )
    );

    expect(getByText("Birth country"));
    expect(queryAllByText("China")[0]);
    expect(getByText("Visa type"));
    expect(getByText("EB-1"));
    expect(getByText("Married?"));
    expect(queryAllByText("Yes")[0]);
    expect(getByText("Filing with spouse?"));
    expect(queryAllByText("Yes")[1]);
    expect(getByText("Spouse birth country"));
    expect(queryAllByText("China")[1]);
  });
});
