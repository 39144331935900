module.exports = {
  blue: "#2680FA",
  blueDark: "#275BC0",
  blueLight: "#80A6F2",
  blueLighter: "#E9F2FE",
  blueLightest: "#F5F9FF",

  green: "#41CD7E",
  gold: "#F5D75B",

  gray: "#B9BFCB",
  grayDark: "#818DA4",
  grayDarker: "#3A4861",
  grayDarkest: "#0B1C38",
  grayLight: "#E9EDF0",
  grayLighter: "#F1F5F9",
  grayLightest: "#F8FAFC",

  orange: "#ff784f",
  red: "#FF5C5C",
  white: "#FFF"
};
