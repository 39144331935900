import { formHeaders } from "../utils/form";

export const sendConfirmationEmail = async (personId, popup) => {
  try {
    const url = Routes.send_welcome_email_client_path(personId);

    const response = await fetch(url, {
      method: "POST",
      headers: formHeaders(),
      body: JSON.stringify({})
    });

    if (!response.ok) {
      popup.show(response.error.message, { type: "error" });
      throw new Error(response.error.message);
    }

    if (response.ok) {
      const json = await response.json();
      popup.show("Confirmation email sent.", { type: "success" });
      return json;
    }
  } catch (error) {
    popup.show(error.message, {
      type: "error"
    });
  }
};
