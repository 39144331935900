import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as colors from "../../../styles/colors";
import { adjustColor } from "../../../utils/styleHelpers";

const TagContainer = styled.div`
  font-weight: 700;
  padding: 0.25rem;
  width: fit-content;
  border-radius: 4px;
  align-items: baseline;
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  background-color: ${props => adjustColor(props.color, 50)};
  margin: ${props => props.margin};

  &:hover {
    color: ${props => adjustColor(props.color, -10)};
  }
`;
export default function StatusTag(props) {
  const { color = colors.orange, text = "Status", margin = "0" } = props;

  return (
    <TagContainer margin={margin} color={color}>
      <span>{text}</span>
    </TagContainer>
  );
}

StatusTag.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  margin: PropTypes.string
};

// Example Usage:
// <StatusTag margin="0 0 0 1rem" color={orange} text="Pending" />
