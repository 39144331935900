import React, { useState, useMemo, useCallback } from "react";
import DataListInput from "../DataListInput/DataListInput";

export default function AutoSuggest(props) {
  const {
    items,
    label,
    placeholder = "Select an option from the drop down menu...",
    initialValue,
    handleSelect,
    handleInputChange,
    inputComponent
  } = props;
  // selectedItem
  const [, setSelectedItem] = useState();

  const onSelect = useCallback(selected => {
    setSelectedItem(selected);
    handleSelect(selected);
  }, []);

  const onInput = useCallback(value => {
    // clear selected item when input value changes
    handleSelect({});
    handleInputChange(value);
  });

  // the array you want to pass to the react-data-list component
  // key and label are required properties
  const memoizedItems = useMemo(
    () =>
      items.map(item => ({
        // required: what to show to the user
        label: item.label,
        // required: key to identify the item within the array
        key: item.id,
        // feel free to add your own app logic to access those properties in the onSelect function
        someAdditionalValue: item.someAdditionalValue,
        // or just keep everything
        ...item
      })),
    [items]
  );

  return (
    <>
      <DataListInput
        label={label}
        placeholder={placeholder}
        items={memoizedItems}
        onSelect={onSelect}
        onInput={onInput}
        initialValue={initialValue}
        inputComponent
      />
    </>
  );
}
