/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import Collapse from "react-css-collapse";
import styled from "styled-components";
import { grayDarker } from "../../../styles/colors";

const Container = styled.div`
  header {
    display: flex;
    align-items: baseline;
    color: ${grayDarker};
    cursor: pointer;
    outline: none;
    h4 {
      font-weight: 400px;
    }
  }
  i {
    width: 36px;
  }
  button {
    width: 100%;
    padding: 1rem;
  }
`;
export default function Dropdown(props) {
  const { name, children } = props;

  const [dropdownOpen, setDropdownOpen] = useState(true);

  return (
    <Container>
      <header
        tabIndex={0}
        role="button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        onKeyDown={() => setDropdownOpen(!dropdownOpen)}
      >
        <i
          title="arrow"
          className={
            dropdownOpen
              ? "u_color-blue far fa-chevron-down"
              : "u_color-blue far fa-chevron-right"
          }
        ></i>
        <h4>{name}</h4>
      </header>
      <Collapse data-testid="css-collapse" isOpen={dropdownOpen}>
        {children}
      </Collapse>
    </Container>
  );
}
