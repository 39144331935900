import { useState } from "react";

export function usePagination(data, ipp) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ipp);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  const nextPage = () => setCurrentPage(cp => Math.min(cp + 1, maxPage));

  const prevPage = () => setCurrentPage(cp => Math.max(cp - 1, 1));

  const currentData = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  const showAll = () => {
    setCurrentPage(1);
    setItemsPerPage(data.length);
  };

  const repaginate = () => setItemsPerPage(ipp);

  const startIndex = (currentPage - 1) * itemsPerPage + 1;

  const endIndex = Math.min(startIndex + itemsPerPage - 1, data.length);

  return {
    nextPage,
    prevPage,
    currentData,
    currentPage,
    setCurrentPage,
    showAll,
    repaginate,
    maxPage,
    startIndex,
    endIndex
  };
}
