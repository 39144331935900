import styled from "styled-components";
import { blue, gray, grayLight, white } from "../../../styles/colors";

export const IndexRow = styled.tr`
  :first-child {
    div,
    th {
      cursor: pointer;
      text-align: left;
      font-weight: 500;
      :last-child {
        margin-right: -0.5rem;
      }
    }
    padding: 1rem;
  }

  &:not(:first-child) {
    background: ${white};
    border: 1px solid ${grayLight};
    border-radius: 8px;
    margin-bottom: 1rem;
    border-left: 4px solid transparent;

    &:hover {
      border-left: 4px solid ${blue};
      box-shadow: 0px 3px 5px ${gray};
      cursor: ${props => (props.clickable ? "pointer" : "")};
    }
  }
  .actions {
    text-align: right;
    button {
      display: inline-block;
    }
  }

  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(min(12rem, 100%), 1fr));
  column-gap: 0.5rem;
  padding: 1rem;

  td:not(:last-child) {
    max-width: max(10rem, 100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
