import React, { createContext, useState, useContext } from "react";

const DashboardContext = createContext(null);

export const useDashboardContext = () => {
  const dashboardContext = useContext(DashboardContext);

  if (dashboardContext === null) {
    throw new Error(
      "useDashboardContext must be used within a DashboardContextProvider tag"
    );
  }
  return dashboardContext;
};

export function DashboardContextProvider({ children }) {
  // this is the global state store for the client dashboard
  const [state, setState] = useState(null);

  return (
    <DashboardContext.Provider value={[state, setState]}>
      {children}
    </DashboardContext.Provider>
  );
}
