import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import HubspotForm from "./HubspotForm";

const DataTable = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);

  p {
    margin-top: 0.5rem;
  }
`;

const FollowUpActions = styled.div`
  margin-top: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  a {
    margin-right: 1rem;
    font-weight: 300;
  }
`;

export default function ApplicationDateResponse(props) {
  const { vibuStatus, clearForm, isMarried, isFilingWithSpouse } = props;

  return (
    <>
      <DataTable>
        <div>
          <h6>Birth country</h6>
          <p>{vibuStatus.birthCountry}</p>
        </div>
        <div>
          <h6>Visa type</h6>
          <p>{vibuStatus.visaCategory}</p>
        </div>
        <div>
          <h6>Priority date</h6>
          <p>{vibuStatus.priorityDate}</p>
        </div>
        <div>
          <h6>Married?</h6>
          <p>{isMarried ? "Yes" : "No"}</p>
        </div>
        <div>
          <h6>Filing with spouse?</h6>
          <p>{isFilingWithSpouse ? "Yes" : "No"}</p>
        </div>
        {vibuStatus.spouseBirthCountry && (
          <div>
            <h6>Spouse birth country</h6>
            <p>{vibuStatus.spouseBirthCountry}</p>
          </div>
        )}
      </DataTable>
      {vibuStatus.currentMonthStatus && (
        <h3>
          Congratulations! You are eligible to file the I-485 based on the{" "}
          <a
            href={vibuStatus.currentVisaBulletin.bulletin_url}
            target="_parent"
          >
            {moment().format("MMMM YYYY")} Visa Bulletin{" "}
          </a>
        </h3>
      )}
      {!vibuStatus.currentMonthStatus && (
        <h3>
          Unfortunately, you are not currently eligible to file the I-485 based
          on the{" "}
          <a
            href={vibuStatus.currentVisaBulletin.bulletin_url}
            target="_parent"
          >
            {moment().format("MMMM YYYY")} Visa Bulletin{" "}
          </a>
        </h3>
      )}
      {vibuStatus.nextMonthStatus && (
        <h3>
          {!vibuStatus.currentMonthStatus ? "However" : "Additionally"}, you
          will be eligible to file the I-485 based on the upcoming{" "}
          <a href={vibuStatus.nextVisaBulletin.bulletin_url} target="_parent">
            {moment()
              .add(1, "M")
              .format("MMMM YYYY")}{" "}
            Visa Bulletin{" "}
          </a>
        </h3>
      )}
      {!vibuStatus.nextMonthStatus && vibuStatus.nextVisaBulletin && (
        <h3>
          {vibuStatus.currentMonthStatus ? "However" : "Additionally"}, you will
          not be eligible to file the I-485 based on the upcoming{" "}
          <a href={vibuStatus.nextVisaBulletin.bulletin_url} target="_parent">
            {moment()
              .add(1, "M")
              .format("MMMM YYYY")}{" "}
            Visa Bulletin{" "}
          </a>
        </h3>
      )}

      {vibuStatus.currentMonthStatus && (
        <>
          <h4>
            What now? We can walk you through your options and answer any
            questions free of charge.
          </h4>
          <FollowUpActions>
            <a
              href="https://legalpad.io/get-started/"
              className="button"
              target="_parent"
            >
              Get in touch
            </a>
            <a
              onClick={() => clearForm()}
              onKeyDown={() => clearForm()}
              role="link"
              tabIndex={0}
            >
              Answer questions again
            </a>
          </FollowUpActions>
        </>
      )}
      {!vibuStatus.currentMonthStatus && (
        <>
          <h4>
            Want to be notified when the State Department releases the next visa
            bulletin? Sign up for notifications below. We will let you know when
            it is time to check your status again.
          </h4>
          <HubspotForm />
          <a
            onClick={() => clearForm()}
            onKeyDown={() => clearForm()}
            role="link"
            tabIndex={0}
          >
            Answer questions again
          </a>
        </>
      )}
    </>
  );
}

ApplicationDateResponse.propTypes = {
  vibuStatus: PropTypes.object,
  isMarried: PropTypes.bool,
  isFilingWithSpouse: PropTypes.bool
};
