import React from "react";
import styled from "styled-components";
import {
  white,
  grayLight,
  gray,
  grayDarkest,
  red
} from "../../../styles/colors";
import UserAvatar from "../UserAvatar";

const UserMenuBox = styled.div`
  background: ${white};
  border-radius: 6px;
  width: 229px;
  height: auto;
  position: absolute;
  top: 5rem;
  right: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 5px ${gray};

  hr {
    margin: 1rem -1.5rem;
    height: 1px;
    background-color: ${grayLight};
    border: none;
  }

  text-align: left;

  ul {
    display: grid;
    margin-bottom: 0;
    a {
      font-size: 16px;
      font-weight: 300;
      color: ${grayDarkest};
      &:visited {
        color: ${grayDarkest};
      }
      :last-child {
        margin-top: 0.5rem;
      }
    }
  }
`;

export default function UserMenu(props) {
  const { user, avatarInitials } = props;

  return (
    <UserMenuBox>
      <UserAvatar size={100}>
        <span>{avatarInitials}</span>
      </UserAvatar>
      <h5>{user.fullName}</h5>
      <small>{user.email}</small>
      <hr />
      <ul>
        {/* eslint-disable-next-line no-undef */}
        <li>
          <a href="/users/edit">Settings</a>
        </li>
        <li>
          <a href="/help-center" target="_blank" rel="noopener noreferrer">
            Help Center
          </a>
        </li>
        <li>
          <a href="mailto:product@legalpad.io">Feedback</a>
        </li>
      </ul>
      <hr />
      <a
        rel="nofollow"
        data-method="delete"
        href="/users/sign_out"
        style={{ color: red }}
      >
        Sign Out
      </a>
    </UserMenuBox>
  );
}
