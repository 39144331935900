/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import UserMenu from "../UserMenu/UserMenu";
import UserAvatar from "../UserAvatar";
import { avatarInitials } from "../../../utils/userHelpers";
import {
  blue,
  grayDark,
  grayDarker,
  grayDarkest,
  grayLighter
} from "../../../styles/colors";
import { formHeaders } from "../../../utils/form";

const NavIcon = styled.img`
  margin-right: 1.5rem;
  cursor: pointer;
`;

const Drawer = styled.nav`
  &&& {
    position: fixed;
    top: 64;
    left: 0;
    z-index: 10;
    padding: 1.5rem;
    height: 100%;
    width: 14rem;
    display: flex;
    flex-direction: column;
    background-color: ${grayLighter};
    box-shadow: 0px 6px 0px ${grayLighter};

    a {
      font-size: 1em;
      color: ${grayDarker};
      font-weight: 300;
      &:hover {
        color: ${blue};
      }
      margin: 0.5rem 0;

      i {
        width: 0.5rem;
        margin-right: 1.5rem;
      }
    }
  }
`;

const MenuItem = styled.a`
  &&& {
    font-weight: ${props => (props.isActive ? 600 : 300)} !important;
  }
`;

const Overlay = styled.div`
  z-index: 9;
  position: fixed;
  top: 64;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
`;

function NavDrawer(props) {
  const { useBrowserRouter } = props;
  const { pathname } = window.location;

  return (
    <Drawer>
      <MenuItem isActive={pathname === "/petitions"} href="/petitions">
        <i className="fas fa-th-list" />
        Petitions
      </MenuItem>
      <MenuItem isActive={pathname === "/people"} href="/people">
        <i className="fas fa-user-friends" />
        People
      </MenuItem>
      <MenuItem isActive={pathname === "/employees"} href="/employees">
        <i className="far fa-address-card" />
        Employees
      </MenuItem>
      <MenuItem isActive={pathname === "/clients"} href="/clients">
        <i className="fas fa-address-card" />
        Clients
      </MenuItem>
      <MenuItem
        isActive={pathname === "/help-center"}
        href="/help-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-info-circle" />
        Help Center
      </MenuItem>
      {!useBrowserRouter && (
        <>
          <MenuItem
            isActive={pathname === "/sales/deals/new"}
            href="/sales/deals/new"
          >
            <i className="fas fa-plus" />
            New Deal
          </MenuItem>
          <MenuItem isActive={pathname === "/sales/deals"} href="/sales/deals">
            <i className="fas fa-book-dead" />
            Deals
          </MenuItem>
          <MenuItem
            isActive={pathname === "/sales/services"}
            href="/sales/services"
          >
            <i className="fas fa-globe-americas" />
            Services
          </MenuItem>
          <MenuItem
            isActive={pathname === "/sales/workflows"}
            href="/sales/workflows"
          >
            <i className="fas fa-archive" />
            Workflows
          </MenuItem>
        </>
      )}
      {/* use react router when possible for speedy transitions */}
      {/* common nav must be wrapped in `<Router></Router>` */}
      {useBrowserRouter && (
        <>
          <NavLink exact to="/deals/new" activeStyle={{ fontWeight: 600 }}>
            <i className="fas fa-plus" />
            New Deal
          </NavLink>

          <NavLink exact to="/deals" activeStyle={{ fontWeight: 600 }}>
            <i className="fas fa-book-dead" />
            Deals
          </NavLink>

          <NavLink exact to="/services" activeStyle={{ fontWeight: 600 }}>
            <i className="fas fa-globe-americas" />
            Services
          </NavLink>

          <NavLink
            to="/workflows"
            exact
            href="/sales/workflows"
            activeStyle={{ fontWeight: 600 }}
          >
            <i className="fas fa-archive" />
            Workflows
          </NavLink>
        </>
      )}
    </Drawer>
  );
}

export default function CommonNav(props) {
  const {
    home = { text: "Legalpad", href: "/petitions" },
    title = null,
    subTitle = null,
    useBrowserRouter = false
  } = props;

  const { pathname } = window.location;

  const parsedTitle = pathname.split("/")[2];

  const [user, setUser] = useState({});

  const [navVisible, setNavVisible] = useState(false);

  const [showUserMenu, setShowUserMenu] = useState(false);

  useEffect(() => {
    setNavVisible(false);
  }, [pathname]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const url = Routes.users_current_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          alert("Something went wrong fetching the current user");
        }

        if (response.ok) {
          const json = await response.json();
          setUser(json);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchUser();
  }, []);

  return (
    <>
      <header className="fixed-header">
        <div className="left">
          {user.superuser && (
            <NavIcon
              src={
                navVisible
                  ? require("images/openNav.svg")
                  : require("images/nav.svg")
              }
              alt="menu"
              onClick={() => setNavVisible(!navVisible)}
            />
          )}
          <a href={home.href} style={{ color: grayDarkest }}>
            {home.text}
          </a>
          {(title || parsedTitle) && (
            <i
              className="far fa-chevron-right"
              style={{ color: grayDark, marginLeft: "2rem" }}
            />
          )}
          <a href={title?.href}>
            <div style={{ color: grayDark, textTransform: "capitalize" }}>
              {title?.text ?? parsedTitle}
            </div>
          </a>
          {subTitle && (
            <i
              className="far fa-chevron-right"
              style={{ color: grayDark, marginLeft: "2rem" }}
            />
          )}
          <a href={subTitle?.href}>
            <div style={{ color: blue }}>{subTitle?.text}</div>
          </a>
        </div>
        <div className="right">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setShowUserMenu(!showUserMenu)}
            onKeyDown={() => setShowUserMenu(!showUserMenu)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <UserAvatar size={42}>
              <span>{avatarInitials(user.firstName, user.lastName)}</span>
            </UserAvatar>
            <i
              style={{ color: blue, marginLeft: "1rem", cursor: "pointer" }}
              className={`fas ${
                showUserMenu ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>
          {showUserMenu && (
            <UserMenu
              user={user}
              avatarInitials={avatarInitials(user.firstName, user.lastName)}
            />
          )}
        </div>
      </header>
      {navVisible && (
        <>
          <NavDrawer useBrowserRouter={useBrowserRouter} />
          <Overlay onClick={() => setNavVisible(!navVisible)} />
        </>
      )}
    </>
  );
}

CommonNav.propTypes = {
  home: PropTypes.object,
  title: PropTypes.object
};
