import styled from "styled-components";
import {
  gray,
  grayDarkest,
  grayLight,
  red,
  white
} from "../../../styles/colors";

export const ContextMenu = styled.div`
  background: ${white};
  border-radius: 2px;
  width: auto;
  height: auto;
  box-shadow: 0px 3px 5px ${gray};

  a {
    padding: 1rem;
    height: 100%;
    width: 100%;
    color: ${grayDarkest};
    &:visited {
      color: ${grayDarkest};
    }
    :last-child {
      color: ${red};
    }
  }

  hr {
    margin: 0;
    height: 1px;
    background-color: ${grayLight};
    border: none;
  }
`;
