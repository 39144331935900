import React from "react";
import PropTypes from "prop-types";

export default function FileUpload(props) {
  const { attribute = {}, setLoading, filesPresent = false } = props;

  const handleChange = e => {
    setLoading(true);
    props.handleUpload(e);
  };

  const inputId = Math.random();

  return (
    <>
      <input
        type="hidden"
        name="entity_type"
        value={attribute.fileData.entityType}
      />
      <input
        type="hidden"
        name="entity_id"
        value={attribute.fileData.entityId}
      />
      <input type="hidden" name="add_item" value />
      <input
        name={`entity_attributes[${attribute.name}]`}
        type="file"
        accept="image/png, image/jpeg, application/pdf"
        className="inputfile"
        id={inputId}
        multiple={false}
        onChange={e => handleChange(e)}
      />
      <label htmlFor={inputId} className="button u_margin-top-1rem">
        <strong>
          {attribute.fileData.files?.length < 1 || !filesPresent
            ? "Choose a file"
            : "Add another file"}
        </strong>
      </label>
    </>
  );
}

FileUpload.propTypes = {
  attribute: PropTypes.object,
  setLoading: PropTypes.func,
  // eslint-disable-next-line react/boolean-prop-naming
  filesPresent: PropTypes.bool
};
