import styled from "styled-components";
import { grayDark } from "../../../styles/colors";

export const IndexItem = styled.div`
  font-weight: ${props => (props.bold ? 700 : 300)};
  color: ${props => (props.grayDark ? grayDark : "")};
  margin-bottom: ${props => (props.marginBottom ? "0.25rem" : "")};
  display: flex;
  justify-content: left;
  align-items: center;
`;
