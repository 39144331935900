import React from "react";
import Dropdown from "../Dropdown/__Dropdown";

export default function SectionDropdowns(props) {
  const { sections } = props;

  return sections.map((section, index) => (
    <section
      data-testid={section.name}
      id={`${section.name.toLowerCase().replace(/\s/g, "")}`}
      key={index}
    >
      <Dropdown name={section.name} dropdownContent={section.content} />
    </section>
  ));
}
