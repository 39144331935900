import React from "react";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "../Shared/AlertTemplate";
import ApplicationDateQuestionare from "./ApplicationDateQuestionare";

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  offset: "30px"
};

export default function ApplicationDateQuestionaireWrapper(props) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <ApplicationDateQuestionare {...props} />
    </AlertProvider>
  );
}
