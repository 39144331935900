import React, { createContext, useState, useEffect, useContext } from "react";
import { formHeaders } from "../../../utils/form";

const AuthContext = createContext(null);

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider tag"
    );
  }
  return authContext;
};

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const url = Routes.users_current_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          alert("Something went wrong fetching the current user");
        }

        if (response.ok) {
          const json = await response.json();
          setUser(json);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchUser();
  }, []);

  if (user)
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
  return <div />;
};
