import React from "react";
import styled from "styled-components";

const DeleteConfirmContainer = styled.div`
  max-width: 24rem;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 1rem;
  }
`;

export default function DeleteConfirmModal(props) {
  const { setDeleteConfirmVisible, handleDelete, itemId } = props;

  return (
    <DeleteConfirmContainer>
      <strong>Delete this note?</strong>
      <div>
        <button
          id={`delete-confirm-button-${itemId}`}
          type="button"
          className="text-button small cancel"
          onClick={() => setDeleteConfirmVisible(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          id={`delete-confirm-button-${itemId}`}
          className="text-button small delete"
          onClick={() => handleDelete(itemId)}
        >
          Delete
        </button>
      </div>
    </DeleteConfirmContainer>
  );
}
