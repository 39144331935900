import React from "react";
import styled from "styled-components";
import { blue, gray, white } from "../../../styles/colors";

const SearchBarInput = styled.input`
  background: ${white};
  border: 1px solid ${gray};
  border-radius: 6px;
  padding: 1rem;
  width: 562px;

  &:focus {
    outline: 1px solid ${blue};
  }
`;

export default function SearchBar(props) {
  const {
    id,
    dataTestId,
    placeholder,
    searchStringValue,
    setSearchStringEvent
  } = props;

  return (
    <SearchBarInput
      id={id}
      data-testid={dataTestId}
      placeholder={placeholder}
      value={searchStringValue}
      onChange={e => setSearchStringEvent(e.target.value)}
    />
  );
}
