import React from "react";

export default function RadioButton(props) {
  /*
   Props: {
     name<string>:            name of the attribute being passed to the server
     label<string>:           display name of the radio button option
     value<string>:           value to be sent to the server
     onChange<func>:          function of how to handle change
     defaultChecked<boolean>: True/False if the radio button should be checked
     labelClassName<string>:  class to be applied to the label
   }
  */

  const { labelClassName, label, ...inputProps } = props;
  return (
    <label className={labelClassName}>
      <input {...inputProps} type="radio" data-testid={`${label}-radio`} />
      <div className="radio-button">{label}</div>
    </label>
  );
}
