import React from "react";
import RadioButton from "./RadioButton";

export default function RadioButtonGroup(props) {
  /*
   Props: {
     options<array>:          array of options
     value<string>:           existing value if any
     name<string>:            name of the attribute being passed to the server
     onChange<func>:          function of how to handle change
     labelClassName<string>:  class to be applied to the label
   }
  */

  return props.options.map(option => {
    const [label, value] = option;
    const attributeValue = props.value;
    return (
      <RadioButton
        key={label}
        name={props.name}
        label={label}
        value={value}
        onChange={props.onChange}
        defaultChecked={attributeValue === value.toString()}
        labelClassName={props.labelClassName}
      />
    );
  });
}
