import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";
import { useAlert } from "react-alert";
import RadioButtonGroup from "../Shared/RadioButtonGroup";
import { formHeaders } from "../../utils/form";
import { red } from "../../styles/colors";
import ApplicationDateResponse from "./ApplicationDateResponse";

const Logo = styled.header`
  padding: 1rem;
`;

const VibuHeader = styled.div`
  margin-bottom: 3rem;

  h5 {
    font-size: 18px;
    color: gray;
  }

  p {
    font-size: 24px;
    max-width: 68rem;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  main {
    padding: 0 4rem;
  }

  .red {
    color: ${red};
  }
`;

const Input = styled.input`
  padding: 0.5rem;
  width: 16rem;
  border-radius: 4px;
  border: 1px solid #b9bfcb;
`;

const ToolTip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px lightgray;
  background-color: white;
  padding: 0.5rem;
  margin-top: 1rem;

  i {
    cursor: pointer;
  }
`;

export default function ApplicationDateQuestionare(props) {
  const { countriesList = [], visaCategoriesList = [], logoUrl = null } = props;

  const countryOptions = countriesList.map((c, i) => ({ value: i, label: c }));
  const visaOptions = visaCategoriesList.map((v, i) => ({
    value: i,
    label: v
  }));

  const [selectedBirthCountry, setSelectedBirthCountry] = useState(null);
  const [selectedVisaCategory, setSelectedVisaCategory] = useState(null);
  const [priorityDate, setPriorityDate] = useState("");
  const [isMarried, setIsMarried] = useState(null);
  const [isFilingWithSpouse, setIsFilingWithSpouse] = useState(null);
  const [spouseBirthCountry, setSpouseBirthCountry] = useState(null);

  const [vibuStatus, setVibuStatus] = useState();

  const [submitted, setSubmitted] = useState(false);

  // Tooltip hide/show
  const [piToolTipVisible, setPiToolTipVisible] = useState(false);
  const [siToolTipVisible, setSiToolTipVisible] = useState(false);

  const clearForm = () => {
    setSubmitted(false);
    setSelectedBirthCountry(null);
    setSelectedVisaCategory(null);
    setPriorityDate("");
    setIsMarried(null);
    setIsFilingWithSpouse(null);
    setSpouseBirthCountry(null);
    setVibuStatus(null);
  };

  const [errors, setErrors] = useState({
    birthCountry: null,
    visaCategory: null,
    isMarried: null,
    isFilingWithSpouse: null,
    spouseBirthCountry: null
  });

  const alert = useAlert();

  const handleSubmit = async () => {
    setSubmitted(true);

    const hasErrors = Object.values(errors).some(x => x !== null);

    if (hasErrors) {
      const messages = Object.keys(errors).map(key => errors[key]);
      return messages.forEach(err =>
        err ? alert.show(err, { type: "error" }) : null
      );
    }

    try {
      const url = Routes.visa_bulletin_application_date_status_path();

      const params = {
        birth_country: selectedBirthCountry.label,
        spouse_birth_country: spouseBirthCountry?.label ?? null,
        visa_category: selectedVisaCategory.label,
        priority_date: priorityDate ?? null
      };

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: formHeaders()
      });

      if (!response.ok) {
        alert.show("Something went wrong", { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setVibuStatus(json);
        alert.show("Great, thanks for filling that out!", { type: "success" });
      }
    } catch (error) {
      alert.show(error, { type: "error" });
    }
  };

  const filingWithSpouseError = () => {
    if (isMarried && isFilingWithSpouse === null) {
      return "Please indicate if you are filing with your spouse";
    }
    return null;
  };

  const spouseBirthCountryError = () => {
    if (isFilingWithSpouse && spouseBirthCountry == null) {
      return "Please select your spouses country of birth";
    }
    return null;
  };

  useEffect(() => {
    // setSubmitted(false);
    setErrors({
      birthCountry: selectedBirthCountry
        ? null
        : "Please select your country of birth",
      visaCategory: selectedVisaCategory
        ? null
        : "Please select your desired visa category",
      isMarried:
        isMarried === true || isMarried === false
          ? null
          : "Please indicate your marriage status",
      isFilingWithSpouse: filingWithSpouseError(),
      spouseBirthCountry: spouseBirthCountryError()
    });
  }, [
    selectedBirthCountry,
    selectedVisaCategory,
    isMarried,
    isFilingWithSpouse,
    spouseBirthCountry
  ]);

  return (
    <Container>
      <Logo>
        <img className="logo" src={logoUrl} alt="Legalpad logo" />
      </Logo>
      <main>
        {!vibuStatus && (
          <VibuHeader>
            <h5>Visa Bulletin</h5>
            <p>
              5 easy questions to find out if you can file your Green Card
              application.
            </p>
          </VibuHeader>
        )}
        {vibuStatus && (
          <ApplicationDateResponse
            vibuStatus={vibuStatus}
            clearForm={clearForm}
            isMarried={isMarried}
            isFilingWithSpouse={isFilingWithSpouse}
          />
        )}
        {!vibuStatus && (
          <>
            <section className="input-prompt-section">
              <h4>
                Where were you born?{" "}
                {submitted && errors.birthCountry && (
                  <i className="fa fa-exclamation-circle red" />
                )}
              </h4>
              <div
                data-testid="select-birth-country"
                style={{ width: "200px" }}
              >
                <Select
                  value={selectedBirthCountry}
                  options={countryOptions}
                  onChange={setSelectedBirthCountry}
                  className="select-birth-country"
                  classNamePrefix="vibu"
                  isClearable
                  placeholder="Select Country..."
                />
              </div>
            </section>
            <section className="input-prompt-section">
              <h4>
                What I-140 Employment Based Category are you applying for?{" "}
                {submitted && errors.visaCategory && (
                  <i className="fa fa-exclamation-circle red" />
                )}
              </h4>
              <div
                style={{ width: "200px" }}
                data-testid="select-visa-category"
              >
                <Select
                  data-testid="select"
                  value={selectedVisaCategory}
                  options={visaOptions}
                  onChange={setSelectedVisaCategory}
                  className="select-visa-category"
                  classNamePrefix="vibu"
                  isClearable
                  placeholder="Select Category..."
                />
              </div>
            </section>
            <section
              className="input-prompt-section"
              data-testid="input-priority-date"
            >
              <h4>
                Do you have an existing I-140 Priority Date? (if not, leave this
                blank){" "}
                <small>
                  <a
                    onClick={() => setPiToolTipVisible(!piToolTipVisible)}
                    onKeyDown={() => setPiToolTipVisible(!piToolTipVisible)}
                    role="button"
                    tabIndex={0}
                  >
                    What is a priority date?
                  </a>
                </small>
                {piToolTipVisible && (
                  <ToolTip>
                    <span>
                      This is your place in line. It determines when you can
                      file the green card application (I-485). For more
                      information{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.legalpad.io/how-to-read-and-understand-the-visa-bulletin/"
                      >
                        visit our blog
                        <i
                          style={{ marginLeft: "0.5rem" }}
                          className="fas fa-external-link"
                        />
                      </a>
                    </span>
                    <i
                      className="fa fa-window-close red"
                      aria-hidden="true"
                      onClick={() => setPiToolTipVisible(false)}
                    />
                  </ToolTip>
                )}
              </h4>
              <Input
                type="date"
                value={priorityDate}
                onChange={e => setPriorityDate(e.target.value)}
              />
            </section>
            <section
              className="input-prompt-section"
              data-testid="yes-no-married"
            >
              <h4>
                Are you legally married?{" "}
                {submitted && errors.isMarried && (
                  <i className="fa fa-exclamation-circle red" />
                )}
              </h4>
              <RadioButtonGroup
                options={[
                  ["Yes", true],
                  ["No", false]
                ]}
                value={isMarried}
                name="isMarried"
                onChange={e => setIsMarried(e.target.value === "true")}
                labelClassName="input-button-label small inline"
              />
            </section>
            {isMarried && (
              <section
                className="input-prompt-section"
                data-testid="yes-no-filing-with-spouse"
              >
                <h4>
                  Will you and your spouse be filing the I-485 at the same time?{" "}
                  <small>
                    <a
                      onClick={() => setSiToolTipVisible(!siToolTipVisible)}
                      onKeyDown={() => setSiToolTipVisible(!siToolTipVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      Why is this important?
                    </a>
                  </small>
                  {siToolTipVisible && (
                    <ToolTip>
                      <span>
                        If you and your spouse are filing the I-485 at the same
                        time, you can use either your or your spouse’s country
                        of birth, whichever bulletin date is earlier in line.
                        For more information{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.legalpad.io/how-to-read-and-understand-the-visa-bulletin/"
                        >
                          visit our blog
                          <i
                            style={{ marginLeft: "0.5rem" }}
                            className="fas fa-external-link"
                          />
                        </a>
                      </span>
                      <i
                        className="fa fa-window-close red"
                        aria-hidden="true"
                        onClick={() => setSiToolTipVisible(false)}
                      />
                    </ToolTip>
                  )}
                  {submitted && errors.isFilingWithSpouse && (
                    <i className="fa fa-exclamation-circle red" />
                  )}
                </h4>
                <RadioButtonGroup
                  options={[
                    ["Yes", true],
                    ["No", false]
                  ]}
                  value={isFilingWithSpouse}
                  name="isFilingWithSpouse"
                  onChange={e =>
                    setIsFilingWithSpouse(e.target.value === "true")
                  }
                  labelClassName="input-button-label small inline"
                />
              </section>
            )}
            {isMarried && isFilingWithSpouse && (
              <section
                className="input-prompt-section"
                data-testid="select-spouse-country-of-birth"
              >
                <h4>
                  What is your spouse's country of birth?{" "}
                  {submitted && errors.spouseBirthCountry && (
                    <i className="fa fa-exclamation-circle red" />
                  )}
                </h4>
                <div style={{ width: "200px" }}>
                  <Select
                    data-testid="select"
                    value={spouseBirthCountry}
                    options={countryOptions}
                    onChange={setSpouseBirthCountry}
                    className="petition-assignee-select"
                    classNamePrefix="petition-assignee"
                    isClearable
                    placeholder="Select Country..."
                  />
                </div>
              </section>
            )}
            <button
              type="button"
              style={{ marginBottom: "3rem" }}
              onClick={() => handleSubmit()}
            >
              Check your eligibility
            </button>
          </>
        )}
      </main>
    </Container>
  );
}

ApplicationDateQuestionare.propTypes = {
  countriesList: PropTypes.array,
  visaCategoriesList: PropTypes.array
};
