import React from "react";
import styled from "styled-components";

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

  margin: 1rem;
  height: fit-content;
  max-width: 500px;
  box-shadow: 0px 1px 2px #e8e8e8;
  background-color: #ffffff;
  border-left: 3px;
  border-radius: 4px;
  display: inline-flex;
  animation: grow 0.5s ease-in forwards;
  font-family: "Inter";
`;

const Rectangle = styled.div`
  height: auto;
  width: 4px;
  border-radius: 4px;
  background-color: ${props => props.color};
`;

const Icon = styled.i`
  align-self: center;
  font-size: 30px;
  margin-right: 1rem;
  color: ${props => props.color};
`;

const Message = styled.div`
  width: fit-content;
  font-size: 18px;
  align-self: center;
`;

const Info = styled.div`
  display: inline-flex;
  font-size: 16px;
  padding: 1rem;
  line-height: 20px;
`;

export default function AlertTemplate({ options, message }) {
  let color;
  let icon;

  switch (options.type) {
    case "error":
      color = "#FF5C5C";
      icon = "far fa-exclamation-circle";
      break;
    case "success":
      color = "#41CD7E";
      icon = "far fa-check-circle";
      break;
    default:
      color = "#2680FA";
      icon = "fas fa-arrow-alt-circle-right";
  }

  return (
    <Container className="popup-message">
      <Rectangle color={color} />
      <Info>
        <Icon alt="icon" className={icon} color={color} />
        <Message>{message}</Message>
      </Info>
    </Container>
  );
}
