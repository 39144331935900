import styled from "styled-components";
import { blue, grayDark } from "../../../styles/colors";

export const PaginationWidget = styled.div`
  width: 100%;
  display: flexbox;
  justify-content: flex-end;
  align-items: baseline;
  color: ${grayDark};
  margin-top: ${props => (props.upshift ? "-2rem" : "")};

  p {
    margin: 0;
  }

  a {
    margin-left: 0.75rem;
  }

  i {
    margin: 0 0.5rem;
    color: ${props => (props.showingAll ? grayDark : blue)};
    cursor: ${props => (props.showingAll ? "default" : "pointer")};

    &:first-of-type {
      color: ${props => (props.currentPage === 1 ? grayDark : blue)};
      cursor: ${props => (props.currentPage === 1 ? "default" : "pointer")};
    }

    &:last-of-type {
      color: ${props =>
        props.currentPage === props.maxPage ? grayDark : blue};
      cursor: ${props =>
        props.currentPage === props.maxPage ? "default" : "pointer"};
    }
  }
`;
