import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { blue, blueLightest } from "../../styles/colors";
import { adjustColor } from "../../utils/styleHelpers";

const Button = styled.button`
  &&& {
    color: ${blue};
    background-color: white;
    border: 1px solid ${blue};
    border-radius: 4px;
    padding: 0.75rem 1rem;
    margin: 1rem 0;
    &:hover {
      color: ${adjustColor(blue, -18)};
      border: 1px solid ${adjustColor(blue, -18)};
      background-color: ${blueLightest};
    }
  }
`;

export default function ButtonSecondary(props) {
  const { text, onClick, isDisabled, dataTestId } = props;
  const disabledClass = isDisabled ? "disabled" : "";
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      className={disabledClass}
      data-testid={dataTestId}
      {...props}
    >
      {text}
    </Button>
  );
}

ButtonSecondary.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string
};
