import styled from "styled-components";
import { blue, gray, red } from "../../../styles/colors";

export const FormInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => (props.error ? red : gray)};
  border-radius: 4px;
  width: ${props => props.width};
  &:focus {
    border: 1px solid ${blue};
  }
`;
