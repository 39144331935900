import { render, waitFor } from "@testing-library/react";
import CommonNav from "./CommonNav";
import { mockUser } from "../../../testHelpers/userMocks";

describe("The CommonNav component", () => {
  // eslint-disable-next-line no-console
  window.alert = alert => console.warn(alert);

  beforeEach(() => {
    fetch.once(JSON.stringify(mockUser));
  });

  afterEach(() => {
    fetch.resetMocks();
  });

  it("renders without crashing", async () => {
    const { container } = render(
      <CommonNav home={{ text: "Homepage", href: "/" }} />
    );
    await waitFor(() => expect(container));
  });

  it("makes a fetch call for the user and updates the ui", async () => {
    const { getByText } = render(
      <CommonNav home={{ text: "Homepage", href: "/" }} />
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
      expect(getByText("HS"));
    });
  });
});
