import React, { useEffect } from "react";
import { useScript } from "../../hooks/useScript";

export default function HubspotForm(props) {
  const status = useScript("//js.hsforms.net/forms/v2.js");

  useEffect(() => {
    if (status === "ready") {
      window.hbspt.forms.create({
        portalId: "5589333",
        formId: "078acabb-8bf7-4a66-9c96-18c77b3ccc19",
        target: "#hubspot-form"
      });
    }
  }, [status]);

  if (status !== "ready") {
    return <p>Loading...</p>;
  }

  return <div id="hubspot-form" />;
}
