/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import Collapse from "react-css-collapse";
import { useDeprecated } from "../../../hooks/useDeprecated";

export default function __Dropdown(props) {
  const { name, dropdownContent, children } = props;

  const [dropdownOpen, setDropdownOpen] = useState(true);

  useDeprecated(__Dropdown, "Please use Shared/Dropdown/Dropdown instead");

  return (
    <>
      <h3
        style={{ width: "fit-content" }}
        className="dropdown-header u_font-weight-400"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <i
          title="arrow"
          className={
            dropdownOpen
              ? "u_color-blue far fa-chevron-down"
              : "u_color-blue far fa-chevron-right"
          }
        ></i>{" "}
        {name}
      </h3>
      <Collapse data-testid="css-collapse" isOpen={dropdownOpen}>
        {dropdownContent}
        {children}
      </Collapse>
    </>
  );
}
