export const generateDocumentReducer = (state, action) => {
  switch (action.type) {
    case "GENERATE_STARTED": {
      return {
        ...state,
        loading: true,
        generated: false,
        popupMessage: null,
        popupType: null
      };
    }
    case "GENERATE_COMPLETE": {
      return {
        ...state,
        generated: true,
        response: action.response,
        popupMessage: "Your document was successfully generated.",
        popupType: "success",
        loading: false
      };
    }
    case "GENERATE_ERROR": {
      return {
        ...state,
        generated: false,
        popupMessage: `Your document could not be generated. ${action.popupMessage}`,
        popupType: "error",
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
