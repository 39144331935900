import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHotkeys } from "../../../hooks/useHotKeys";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";

const handleAnimation = ({ open, reverse }) => {
  if (reverse) {
    if (open) return "roadRunnerInReverse";
    return "roadRunnerOutReverse";
  }
  if (open) return "roadRunnerIn";
  return "roadRunnerOut";
};

const Container = styled.div`
  padding: 1.75rem;
  background: white;
  border: none;
  border-radius: 6px;
  flex-shrink: 0;
  position: fixed;
  animation: ${props => `${handleAnimation(props)}
    0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards`};
  z-index: 100;
  width: auto;
  box-shadow: 0px 6px 6px 0px 0, 0, 0, 0.75;

  @media (min-width: 900px) {
    width: 528px;
  }
`;

const Overlay = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(58, 72, 97, 0.37);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Modal({ show, reverse, cleanup, children }) {
  const [modalVisible, setModalVisible] = useState(true);
  const [shouldRender, setRender] = useState(show);

  const containerRef = useRef();

  useOnClickOutside(containerRef, () => {
    setModalVisible(false);
    cleanup();
  });

  useHotkeys("esc", () => {
    setModalVisible(false);
    cleanup();
  });

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  if (modalVisible)
    return (
      <Overlay>
        {shouldRender && (
          <Container
            ref={containerRef}
            open={show}
            reverse={reverse}
            onAnimationEnd={onAnimationEnd}
          >
            {children}
          </Container>
        )}
      </Overlay>
    );
  return null;
}
