import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Checkbox } from "./Checkbox";

const Label = styled.label`
  display: flex;
  align-items: baseline;
`;

export default function CheckBoxItem(props) {
  const { checked, handleChange, text, name, value } = props;
  return (
    <Label>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        name={name}
        value={value}
      />
      <span style={{ marginLeft: 8 }}>{text}</span>
    </Label>
  );
}

CheckBoxItem.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  text: PropTypes.string,
  name: PropTypes.string
};
