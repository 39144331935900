import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import PhoneField from "../../FormFields/PhoneField";
import { formHeaders } from "../../../utils/form";
import { blue, grayDarker } from "../../../styles/colors";
import ButtonSecondary from "../ButtonSecondary";
import SelectBox from "../../SalesFlow/SelectBox";

const ExistingPersonPrompt = styled.div`
  width: 21rem;
  &&& {
    h6 {
      margin-bottom: 1rem;
      color: ${grayDarker};
      font-weight: 600;
    }
    a {
      text-decoration: none;
    }
  }
`;

export default function OrganizationPersonForm(props) {
  const { organizationId, updateParentObject, personType = "person" } = props;

  const popup = useAlert();

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [existingPerson, setExistingPerson] = useState(null);

  const [loading, setLoading] = useState(false);

  const createPerson = async () => {
    setLoading(true);
    try {
      const url = Routes.people_organization_path(organizationId);

      const { firstName, lastName, email } = formState;

      const requestBody = {
        person: {
          first_name: firstName,
          last_name: lastName,
          email
        }
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        popup.show("Person could not be created", { type: "error" });
        setLoading(false);
      }

      if (response.ok) {
        const json = await response.json();

        if (json.preexisting) {
          setTimeout(() => {
            setExistingPerson(json.person);
            setLoading(false);
          }, 1500);
        } else {
          updateParentObject(json);
          setFormState({
            firstName: "",
            lastName: "",
            email: ""
          });
          setShowForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
      setLoading(false);
    }
  };

  const handleExistingPerson = person => {
    updateParentObject(person);
    setFormState({
      firstName: "",
      lastName: "",
      email: ""
    });
    setExistingPerson(null);
    setShowForm(false);
  };

  return (
    <>
      {!showForm && (
        <ButtonSecondary
          text={`Add new ${personType}`}
          onClick={() => setShowForm(true)}
        />
      )}
      {showForm && (
        <div style={{ width: "fit-content" }}>
          <div className="inline-remove" style={{ marginTop: "1rem" }}>
            <p>Add a new person</p>
            <a
              data-testid="remove-poc"
              role="button"
              tabIndex="0"
              onClick={() => setShowForm(false)}
              onKeyDown={() => setShowForm(false)}
            >
              REMOVE
            </a>
          </div>
          {existingPerson && (
            <ExistingPersonPrompt>
              <h6>Is this the person you're looking for?</h6>
              <SelectBox
                contact={existingPerson}
                checked
                handleChange={() => {}}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => setExistingPerson(null)}
                  onKeyDown={() => setExistingPerson(null)}
                >
                  No, wrong person.
                </a>
                <button
                  type="button"
                  onClick={() => handleExistingPerson(existingPerson)}
                  onKeyDown={() => handleExistingPerson(existingPerson)}
                >
                  Yes
                </button>
              </div>
            </ExistingPersonPrompt>
          )}
          {!existingPerson && (
            <>
              <div className="text-input-wrapper">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formState.firstName}
                  onChange={e => handleChange(e)}
                ></input>
                <label>First Name</label>
              </div>
              <div className="text-input-wrapper">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formState.lastName}
                  onChange={e => handleChange(e)}
                ></input>
                <label>Last Name</label>
              </div>
              <div className="text-input-wrapper">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={formState.email}
                  onChange={e => handleChange(e)}
                ></input>
                <label>Email</label>
              </div>
              {showDetails && (
                <>
                  <a
                    onClick={() => setShowDetails(false)}
                    onKeyDown={() => setShowDetails(false)}
                    role="button"
                    tabIndex={0}
                  >
                    Hide Details
                  </a>
                  <div className="text-input-wrapper">
                    <PhoneField label="Phone" placeholder="Phone" />
                  </div>
                  <div className="text-input-wrapper">
                    <PhoneField label="Fax" placeholder="Fax" />
                  </div>
                  <h6>US Mailing Address</h6>
                  <div className="text-input-wrapper">
                    <input type="text" placeholder="Address Line 1"></input>
                    <label>Address Line 1</label>
                  </div>
                  <div className="text-input-wrapper">
                    <input type="text" placeholder="Address Line 2"></input>
                    <label>Address Line 2</label>
                  </div>
                  <div className="text-input-wrapper">
                    <input type="text" placeholder="City"></input>
                    <label>City</label>
                  </div>
                  <div className="text-input-wrapper">
                    <input type="text" placeholder="State"></input>
                    <label>State</label>
                  </div>
                  <div className="text-input-wrapper">
                    <input type="text" placeholder="Zip Code"></input>
                    <label>Zip Code</label>
                  </div>
                </>
              )}
              {loading && (
                <Loader
                  type="ThreeDots"
                  color="#2680FA"
                  height={64}
                  width={64}
                />
              )}
              <div style={{ display: "grid" }}>
                <div />
                <button
                  type="button"
                  className="button small secondary"
                  onClick={() => createPerson()}
                  style={{
                    border: `1px solid ${blue}`,
                    width: "6rem",
                    marginLeft: "auto",
                    gridColumn: 2
                  }}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

OrganizationPersonForm.propTypes = {
  organizationId: PropTypes.string,
  updateParentObject: PropTypes.func
};
